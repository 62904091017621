import React, {useEffect, useState} from "react";
import libAPI from "./api";

export const Result = ({step1, step4, setPage, setStep1, setStep2, setStep3, setStep4}) => {
  
  const [seconds, setSeconds] = useState(0);
  const [disableButton, setDisable] = useState(false)
  
  useEffect(()=> {
    if(seconds > 0) {
      setDisable(true)
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }else {
      setDisable(false)
      setSeconds("")
    }
  },[seconds])

  const resendEmail = async () => {
    setSeconds(60)
    try {
      await libAPI.resendEmail({email:step1.email});
    } catch (e) {
      window.alert("Error");
    }
  };

  const handleSelesai = () => {
    setStep1((state) => ({
      ...state,
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      area_phone_number: "+62",
      phone_number: "",
      tgl_lahir: {
        tgl: "",
        bulan: "01",
        tahun: "",
      },
      gender: "1",
      allowAgeRestriction: true,
      first_name: "",
      last_name: "",
      real_phone_number: "",
      age: "",
      username: "",
      errors: {}
    }));
    setStep2((state)=> ({
      ...state,
      profesi: "pelajar",
      jenis_profesi: "",
      status: "menikah",
      pendapatan: "2000000",
      domisili: "",
      errors: {}
    }))
    setStep3(false)
    setStep4(0)
    // setPage(0)
    window.location.href = "https://ebooks.gramedia.com"
  }

  return (
    <div className="container mx-auto">
      {step4 === 200 ?
        <div>
          <div className="mt-10">
            <div className="flex justify-center m-auto text-medium">
              Terima kasih atas partisipasinya.
            </div>
            <p className="md:w-96 w-80 my-10 mx-auto text-center">
              Mohon maaf penawaran ini hanya berlaku untuk pelanggan baru. terima kasih
            </p>
          </div>
          <div className="m-5 flex justify-center">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSelesai}>Selesai</button>
          </div>
        </div>
        :
        <div>
          <div className="mt-10">
            <div className="flex justify-center m-auto text-medium">
              Terima kasih atas partisipasinya.
            </div>
            <p className="md:w-96 w-80 my-10 mx-auto text-center">
              Feedback dari kamu sangat berarti bagi kami. Akses Free Trial Paket Premium selama 7 hari akan dikirimkan melalui email yang terdaftar.
            </p>
          </div>
          <div className="m-5 flex justify-center">
            <div className="text-3xl text-blue-300">{seconds !== 0 && seconds}</div>
          </div>
          <div className="m-5 flex justify-center">
              <button onClick={resendEmail} className={disableButton ? "text-gray-400 font-regular": "text-blue-400 font-regular"} disabled={disableButton}>Belum menerima email ? Verifikasi ulang email anda disini</button>
            </div>
          <div className="m-5 flex justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSelesai}>Selesai</button>
          </div>
        </div>
      }
    </div>
  );
};
