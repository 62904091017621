import React, { useEffect, useState } from "react";
import "./index.css";
import libAPI from "./api";
import { useTranslation } from "react-i18next";

export const Step3 = ({ setPage, setStep3 }) => {
  const { t } = useTranslation();
  const [genre, setGenre] = useState(false);
  const [choice, setChoice] = useState([]);
  const [alertWarn, setAlertWarn] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (choice.length > 0) {
      setStep3(choice);
      setPage(4);
    } else {
      window.alert("Silahkan pilih minimal 1 genre");
    }
  };

  useEffect(() => {
    const getAllGenre = async () => {
      try {
        const { data } = await libAPI.getCategories();
        setGenre(data.categories);
      } catch (e) {
        console.log(e);
        window.alert("Error");
      }
    };
    getAllGenre();
  }, []);

  const change = (item) => {
    let arr = [...choice];
    let index = arr.findIndex(function (o) {
      return o.id === item.id;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      if (choice.length >= 3) {
        setAlertWarn(true);
        setTimeout(() => {
          setAlertWarn(false);
        }, 2500);
      } else {
        arr.push(item);
      }
    }

    setChoice(arr);
  };

  const alert = (
    <div className="flex fixed alert-cstm">
      <div className="m-auto">
        <div className="bg-white rounded-lg border-gray-300 border p-3 shadow-lg">
          <div className="flex flex-row">
            <div className="px-2">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div className="ml-2 mr-6">
              <span className="font-semibold">{t("step3.warning")}</span>
              <span className="block text-gray-500">
                {t("step3.warning_desc")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {alertWarn && alert}
      <div className="mt-10 text-center mb-10">
        <h4>{t("step3.title")}</h4>
        <p>{t("step3.sub_title")}</p>
      </div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-4">
          {genre
            ? genre.map((item, index) =>
                item.is_active ? (
                  <div
                    className="genre"
                    key={index}
                    onClick={() => change(item, index)}
                  >
                    <div
                      className={
                        "gradient " +
                        (choice.some((choose) => choose.id === item.id)
                          ? "active"
                          : "")
                      }
                    >
                      <img
                        src={`${item.media_base_url_categories}${item.thumb_image_normal}`}
                        alt="Gramedia"
                      />
                      {/* <img
                        src={`${item.media_base_url}${item.icon_image_normal}`}
                        alt="Gramedia"
                      /> */}
                      <p>{item.name}</p>
                    </div>
                  </div>
                ) : null
              )
            : null}
        </div>
        <div className="my-8 flex justify-center">
          <button
            type="submit"
            onClick={onSubmit}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};
