import React, {useState} from "react";
import libAPI from "./api";
import { useTranslation } from "react-i18next";
const sha1 = require("js-sha1");

export const Step5 = ({ setPage, step1, step2, step3, step4, setStep4}) => {
  const { t } = useTranslation();
  const [konfirmasi, setKonfirmasi] = useState("setuju");
  const [pilihpaket, setPaket] = useState("");
  const [paketlain, setPaketLain] = useState("")
  const [alasan, setAlasan] = useState("")
  const secretPassword = "5gvi-ojhgf%^&YGBNeds1{"
  const hash = sha1.create();
  hash.update(secretPassword+step1.password)

  const onSubmit = async (e) => {
    e.preventDefault();

    var pilihProfesi = step2.profesi
    if(step2.profesi === "pekerja" && step2.jenis_profesi === ""){
      pilihProfesi = step2.jenis_profesi
    }else{
      pilihProfesi = step2.profesi
    }

    var harga_paket;
    if (konfirmasi === "setuju") {
      harga_paket = 89000;
    } else {
      if (pilihpaket === "lainnya") {
        harga_paket = paketlain;
      } else {
        harga_paket = pilihpaket;
      }
    }

    const dataBaru = {
      username: step1.username,
      password: hash.hex(),
      email: step1.email,
      phone_number: step1.real_phone_number,
      first_name: step1.first_name,
      allow_age_restricted_content: step1.allowAgeRestriction,
      is_display_point: true,
      origin_client_id: 1,
      is_verified: false,
      is_active: true,
      last_name: step1.last_name,
      referral_code: "new_user_seo",
      profile: {
        birthdate: step1.age,
        gender: step1.gender,
      },
      job: pilihProfesi,
      marital_status: step2.status,
      salary: step2.pendapatan,
      domicile: step2.domisili,
      fav_genre: step3.map((res) => res.name),
      suggetion_premium_price: harga_paket, //issue
      user_reason: alasan, //issue
      note: "new_user_seo",
    };

    try {
      const { data } = await libAPI.registerUser(dataBaru);
      setStep4(data.status)
      setPage(5);
    } catch (e) {
      console.log(e);
      window.alert("Error");
    }
  };

  return (
    <div className="md:w-2/6 mx-auto my-10 sm:container w-4/5 mx-5">
      <form onSubmit={onSubmit} className="my-10">
        <div className="my-4">{t("step5.title")}</div>
        <div className="mb-4">
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap w-full">
                <input
                  className="mt-1 mr-2"
                  id="setuju"
                  type="radio"
                  value="setuju"
                  checked={konfirmasi === "setuju"}
                  // onChange={(e) => {
                  //   setStep5((state) => ({
                  //     ...state,
                  //     konfirmasi: e.target.value,
                  //   }, console.log(step5.konfirmasi, 'konfirmasi')
                  //   ));
                  //   // console.log(e.target.value)
                  // }}
                  onChange={(e) => {
                    setKonfirmasi(e.target.value);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="setuju"
                >
                  {t("step5.agree")}
                </label>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="tidak"
                  type="radio"
                  value="tidak"
                  checked={konfirmasi === "tidak"}
                  // onChange={(e) => {
                  //   setStep5((state) => ({
                  //     ...state,
                  //     konfirmasi: e.target.value,
                  //   }));
                  // }}
                  onChange={(e) => {
                    setKonfirmasi(e.target.value);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="tidak"
                >
                  {t("step5.not_agree")}
                </label>
              </div>
              {konfirmasi === "tidak" && (
                <div className="my-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("step5.premium")}
                  </label>
                  <div className="flex">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <div className="flex flex-wrap w-full">
                        <input
                          className="mt-1 mr-2"
                          id="paket50"
                          type="radio"
                          value="50000"
                          checked={pilihpaket === "50000"}
                          // onChange={(e) => {
                          //   setStep5((state) => ({
                          //     ...state,
                          //     paket: e.target.value,
                          //   }));
                          // }}
                          onChange={(e) => {
                            setPaket(e.target.value);
                          }}
                        />
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="50000"
                        >
                          Rp.50.000
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <div className="flex flex-wrap w-full">
                        <input
                          className="mt-1 mr-2"
                          id="paket75"
                          type="radio"
                          value="75000"
                          checked={pilihpaket === "75000"}
                          // onChange={(e) => {
                          //   setStep5((state) => ({
                          //     ...state,
                          //     paket: e.target.value,
                          //   }));
                          // }}
                          onChange={(e) => {
                            setPaket(e.target.value);
                          }}
                        />
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="75000"
                        >
                          Rp.75.000
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-full px-3 mb-3 md:mb-0">
                      <div className="flex flex-wrap w-full">
                        <input
                          className="mt-1 mr-2"
                          id="paketlain"
                          type="radio"
                          value="lainnya"
                          checked={pilihpaket === "lainnya"}
                          // onChange={(e) => {
                          //   setStep5((state) => ({
                          //     ...state,
                          //     paket: e.target.value,
                          //   }));
                          // }}
                          onChange={(e) => {
                            setPaket(e.target.value);
                          }}
                        />
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="lainnya"
                        >
                          {t("step5.other")}
                        </label>
                      </div>
                      {pilihpaket === "lainnya" && (
                        <div>
                          <div className="mb-4">
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="jenispaket"
                              type="number"
                              value={paketlain}
                              // onChange={(e) => {
                              //   setStep5((state) => ({
                              //     ...state,
                              //     harga_paket_lainnya: e.target.value,
                              //     paket: "89000",
                              //   }));
                              // }}
                              onChange={(e) => {setPaketLain(e.target.value)}}
                            />
                          </div>
                          <div className="mb-4">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-2"
                              htmlFor="nama"
                            >
                              {t("step5.explain")}
                            </label>
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="alasan"
                              type="text"
                              value={alasan}
                              // onChange={(e) => {
                              //   setStep5((state) => ({
                              //     ...state,
                              //     alasan: e.target.value,
                              //   }));
                              // }}
                              onChange={(e) => {setAlasan(e.target.value)}}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4 flex justify-center">
          <button className="selesai bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            {t("submit")}
          </button>
        </div>
      </form>
    </div>
  );
};
