import React, {useState} from "react";
import design from "./images/design.png";
import { useTranslation } from "react-i18next";
import { Step1 as Page1 } from "./Step1";
import { Step2 as Page2 } from "./Step2";
import { Step3 as Page3 } from "./Step3";
import { Step5 as Page5 } from "./Step5";
import { Result } from "./Result";

export const Home = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [step1, setStep1] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    area_phone_number: "+62",
    phone_number: "",
    tgl_lahir: {
      tgl: "",
      bulan: "01",
      tahun: "",
    },
    gender: "1",
    allowAgeRestriction: true,
    first_name: "",
    last_name: "",
    real_phone_number: "",
    age: "",
    username: "",
    errors: {}
  });
  const [step2, setStep2] = useState({
    profesi: "pelajar",
    jenis_profesi: "",
    status: "menikah",
    pendapatan: "2000000",
    domisili: "",
    errors: {}
  });
  const [step3, setStep3] = useState(false);
  // const [step5, setStep5] = useState({
  //   konfirmasi: "setuju",
  //   paket: "",
  //   alasan: "",
  //   jenis_paket: "",
  //   harga_paket_lainnya: "",
  // });
  const [step4, setStep4] = useState(0);

  if (page === 1)  return <Page1 setStep1={setStep1} step1={step1} setPage={setPage} />;
  else if (page === 2)  return <Page2 setStep2={setStep2} step2={step2} setPage={setPage} />;
  else if (page === 3) return <Page3 setPage={setPage} setStep3={setStep3} />;
  else if (page === 4)
    return (
      <Page5
        setPage={setPage}
        step1={step1}
        step2={step2}
        step3={step3}
        setStep4={setStep4}
      />
    );
  else if (page === 5) { 
    return (
    <Result step1={step1} step4={step4} setStep1={setStep1} setStep2={setStep2} setStep3={setStep3} setStep4={setStep4} setPage={setPage}/>
    )
  }

  const onSubmit = () => {
    setPage(1)
  };

  return (
    <div className="container mx-auto">
      <div>
        <div className="mt-10">
          <img
            src={design}
            alt={design}
            width="323"
            height="323"
            className="flex justify-center m-auto"
          ></img>
          <p className="md:w-96 w-80 my-10 mx-auto text-center">{t("title")}</p>
        </div>
        <div className="m-5 flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
            onClick={onSubmit}
          >
            {t("follow_survey")}
          </button>
        </div>
      </div>
    </div>
  );
};
