import axios from "axios";

const token =
  "MzU3MTgwOjFhMTY1NzgwMzg5YzEwZjAwNzliNDI3MTQyMGVjZDVmNjdmNDk5ZjY1OWIwYzNkNTlkYjliZDZhNzFmM2E1ODdhYmQyZjAyZGI4YmMwNDVi";
const api = axios.create({
  baseURL: "https://scoopadm.apps-foundry.com/scoopcor/api/v1/", //Production
  // baseURL: "https://dev.apps-foundry.com/scoopcor/api/v1/", //Development
});

const libAPI = {
  getCategories: (url = "categories") => {
    return api.get(`${url}?item_type_id=2&is_active=true&limit=41`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    
  },
  registerUser: (choice, url = "users/seo") => {
    return api.post(`${url}`, choice, {
      headers: {
        "Content-Type": "application/json",
        // "User-Agent": "seo",
      },
    })
    .then((response) => {
      let variabelBaru = {data: {status: response.status, message: response.data.message}}
      return variabelBaru
    })
  },
  resendEmail: (data, url = "users/seo/resend_email") => {
    return api.post(`${url}`, data)
  }
};

export default libAPI;
