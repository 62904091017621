import "./App.css";
import { Header } from "./components/header";
import Footer from "./components/footer";
import { Home } from "./Home";

function App() {
  return (
    <div>
      <div className="shadow">
        <Header />
      </div>
      <Home/>
      <Footer />
    </div>
  );
}

export default App;
