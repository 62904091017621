import { createSlice } from "@reduxjs/toolkit";

// const rootSlice = createSlice({
//   name: "root",
//   initialState: {
//     base: "small",
//     crust: "classic_thin",
//     sauce: "no_sauce",
//     cheese: "no_cheese"
//   },
//   reducers: {
//     chooseBase: (state, action) => { state.base = action.payload },
//     chooseCrust: (state, action) => { state.crust = action.payload },
//     chooseSauce: (state, action) => { state.sauc = action.payload },
//     chooseCheese: (state, action) => { state.cheese = action.payload },
//   }
// })

// export const reducer = rootSlice.reducer;

// export const { chooseBase, chooseCheese, chooseCrust, chooseSauce } = rootSlice.actions

const rootSlice = createSlice({
  name: "root",
  initialState: [
    {
      nama: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phonenumber: "",
      birthDate: "",
      age_restricted: false,
      jenis_kelamin: "",
      kategori: "",
      sub_kategori: "",
      profesi: "",
      marital_status: "",
      salary: "",
      domicile: "",
      fav_genre: "",
      fav_theme: "",
      suggestion_premium_price: "",
      genre: "",
      user_reason:""
    },
  ],
  reducers: {
    dataForm: {
      reducer(state, action) {
        state.push(action.payload);
      },
      prepare(
        nama,
        firstName,
        lastName,
        age_restricted,
        email,
        password,
        phonenumber,
        birthDate,
        jenis_kelamin
      ) {
        return {
          payload: {
            nama,
            firstName,
            lastName,
            age_restricted,
            email,
            password,
            phonenumber,
            birthDate,
            jenis_kelamin,
          },
        };
      },
    },
    dataFormDua: {
      reducer(state, action) {
        state.push(action.payload);
      },
      prepare(profesi, marital_status, salary, domicile) {
        return {
          payload: {
            profesi,
            marital_status,
            salary,
            domicile,
          },
        };
      },
    },
    dataFormTiga: {
      reducer(state, action) {
        state.push(action.payload);
      },
      prepare(genre) {
        return {
          payload: {
            genre,
          },
        };
      },
    },
    dataFormLima: {
      reducer(state, action) {
        state.push(action.payload);
      },
      prepare(suggestion_premium_price, user_reason) {
        return {
          payload: {
            suggestion_premium_price,
            user_reason
          },
        };
      },
    },
  },
});

export const reducer = rootSlice.reducer;

export const { dataForm, dataFormDua, dataFormTiga, dataFormLima } =
  rootSlice.actions;
