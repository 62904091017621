import React, { Component } from 'react'
import logo from '../images/logo.png'

export class Header extends Component{
    render(){
        return (
            <div className="container mx-auto">
                <a href="https://ebooks.gramedia.com">
                <img 
                    // src="https://ebooks.gramedia.com/css/images/logo-gramedia-digital.png" 
                    src={logo}
                    width="200"
                    alt="Gramedia Digital Logo" 
                    title="Toko Buku, Majalah dan Koran Digital - Gramedia Digital Indonesia"
                    className="py-4 mx-auto md:mx-0"
                />
                </a>
            </div>
        )
    }
    
}

export default Header