import React, { useState } from "react";
import facebook from "../images/footer/desktop/icons/ic_facebook.png";
import twitter from "../images/footer/desktop/icons/ic_twitter.png";
import instagram from "../images/footer/desktop/icons/ic_instagram.png";
import bni from "../images/footer/desktop/logo/payment/BNI Virtual Account.png";
import cc from "../images/footer/desktop/logo/payment/Credit Card.png";
import mandiri from "../images/footer/desktop/logo/payment/Mandiri Virtual Account.png";
import gopay from "../images/footer/desktop/logo/payment/Gopay.png";
import gdpoint from "../images/footer/desktop/logo/payment/GD Point.png";
import octoclick from "../images/footer/desktop/logo/payment/Octo Clicks.png";
import octomobile from "../images/footer/desktop/logo/payment/Octo Mobile.png";
import rekponsel from "../images/footer/desktop/logo/payment/Rekening Ponsel.png";
import shopee from "../images/footer/desktop/logo/payment/Shopee Pay.png";
import ovo from "../images/footer/desktop/logo/payment/Ovo.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export const Footer = () => {
  const [language, setLanguage] = useState(Cookies.get("locale") || "en");
  const { t, i18n } = useTranslation();

  function changeLanguage(e) {
    const code = e.target.value;

    if (i18n.language !== code) {
      i18n.changeLanguage(code);
      Cookies.set("locale", code);
      setLanguage(code);
    }
  }
  return (
    <div className="relative">
      <div className="sticky bottom-0 left-0 w-full bg-gray-100 text-black">
        <div className="container xl:px-20 pb-12 lg:px-20 md:px-10 sm:px-5 px-10">
          <div className="w-full pt-12 flex flex-col sm:flex-row space-y-2 justify-start">
            <div className="w-full sm:w-1/5 flex flex-col space-y-4 mr-32">
              <div className="font-bold text-gray-600">About</div>
              <a className="opacity-80 text-gray-600" href="https://ebooks.gramedia.com/blog">
                Blog
              </a>
              <a className="opacity-80 text-gray-600" href="https://ebooks.gramedia.com/id/publisher">
                Publish with Us
              </a>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4 mr-32">
              <div className="font-bold text-gray-600">Help</div>
              <a className="opacity-80 text-gray-600" href="https://ebooks.gramedia.com/id/faq">
                Contact Us
              </a>
              <a className="opacity-80 text-gray-600" href="https://ebooks.gramedia.com/id/terms">
                Terms &amp; Conditions
              </a>
              <a className="opacity-80 text-gray-600" href="https://ebooks.gramedia.com/id/privacy">
                Privacy Policy
              </a>
            </div>
            <div className="w-full sm:w-1/5 flex flex-col space-y-4 mr-32">
              <div className="font-bold text-gray-600">Network</div>
              <a className="opacity-80 text-gray-600" href="https://eperpus.com">
                E-Perpus
              </a>
              <div className="flex flex wrap">
                <a href="https://www.facebook.com/gramediadigital"><img src={facebook} alt={facebook} className="w-8 h-8 mx-1" /></a>
                <a href="https://twitter.com/gramediaebooks"><img src={twitter} alt={twitter} className="w-8 h-8 mx-1" /></a>
                <a href="https://www.instagram.com/gramediadigital"><img src={instagram} alt={instagram} className="w-8 h-8 mx-1" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:px-20 pb-12 lg:px-20 md:px-10 sm:px-5 px-6 bg-white shadow">
          <div className="container w-full pt-2 flex flex-col sm:flex-row space-y-2 justify-start">
            <div className="md:w-4/6 sm:w-1/5 flex flex-col space-y-4">
              <div className="font-bold text-gray-600">Payment Method</div>
              <div className="flex flex-wrap">
                <img src={cc} alt={cc} className="w-16 h-8 mx-2" />
                <img src={mandiri} alt={mandiri} className="w-16 h-8 mx-2" />
                <img src={bni} alt={bni} className="w-16 h-8 mx-2" />
                <img src={gopay} alt={gopay} className="w-12 h-8 mx-2" />
                <img src={ovo} alt={ovo} className="w-10 h-8 mx-2" />
                <img src={gdpoint} alt={gdpoint} className="w-16 h-8 mx-2" />
              </div>
              <div className="flex flex-wrap mt-4">
                <img
                  src={rekponsel}
                  alt={rekponsel}
                  className="w-16 h-8 mx-2"
                />
                <img src={shopee} alt={shopee} className="w-16 h-8 mx-2" />
                <img
                  src={octoclick}
                  alt={octoclick}
                  className="w-16 h-8 mx-2"
                />
                <img
                  src={octomobile}
                  alt={octomobile}
                  className="w-16 h-8 mx-2"
                />
              </div>
            </div>
            <div className="md:w-1/5 sm:w-full flex flex-col space-y-4">
              <div className="flex flex-wrap items-stretch w-full mb-4 relative shadow appearance-none border h-10 mt-5 rounded-full">
                <button
                  defaultValue={language}
                  onClick={changeLanguage}
                  value="en"
                  className={language ===  "en" ? "flex-shrink flex-grow flex-auto leading-normal w-px flex-1 h-10 px-3 relative w-1/2 bg-gray-400 rounded-l-full text-white" : "flex-shrink flex-grow flex-auto leading-normal w-px flex-1 h-10 rounded-l-none px-3 relative w-1/2"}
                >
                  {t("english")}
                </button>
                <button
                  defaultValue={language}
                  onClick={changeLanguage}
                  value="id"
                  className={language ===  "id" ? "flex-shrink flex-grow flex-auto leading-normal w-px flex-1 h-10 rounded-l-none px-3 relative w-1/2 bg-gray-400 rounded-full text-white" : "flex-shrink flex-grow flex-auto leading-normal w-px flex-1 h-10 rounded-l-none px-3 relative w-1/2"}
                >
                  {t("indonesia")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-0 shadow" />
        <div className="xl:px-10 pb-3 lg:px-10 md:px-6 sm:px-5 px-6 bg-white">
          <div className="w-full pt-4 flex flex-col sm:flex-row space-y-2 text-center">
            <div className="md:w-full sm:w-1/5 flex flex-col space-y-4">
              &copy; 2019-2021 All Right Reserved. Gramedia Digital is a
              registered trademark of Kompas Gramedia
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
