import React, { useState } from "react";
import month from "./data/month.json";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import "./index.css";
import { useTranslation } from "react-i18next";

export const Step1 = ({ setStep1, step1, setPage }) => {
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);
  const [showCPass, setCShowPass] = useState(false);
  

  const calculateAge = (data) => {
    var diff = Date.now() - data.getTime();
    var age = new Date(diff);

    return Math.abs(age.getFullYear() - 1970);
  };

  const handleValidateUmur = (data) => {
    const {value, maxLength} = data.target
    const umur = value.slice(0,maxLength)
    setStep1((state) => ({
      ...state,
      tgl_lahir: {
        ...state.tgl_lahir,
        tgl: umur,
      },
    }));
  }

  const handleValidateTahun = (data) => {
    var date = new Date()
    const {value, maxLength} = data.target
    let getYear = date.getFullYear() - 3
    let tahun = value.slice(0,maxLength)
    if(tahun < 1960 && tahun === "0000" && tahun.length === 4){
      tahun  = 1960
    }else if(tahun >= getYear){
      tahun = getYear
    }
    setStep1((state) => ({
      ...state,
      tgl_lahir: {
        ...state.tgl_lahir,
        tahun: tahun,
      },
    }))
  }

  const handleValidation = (data) => {
    let formsIsValid = true
    let errors = {}
    let validateEmailAdress = validateEmail(data.email)
    let validatePass = validatePassword(data.password, data.confirm_password)
    if(data.name === "") {
      formsIsValid = false
      errors["name"] = t("step1.validation")
    }

    if(data.email === "" || !validateEmailAdress){
      formsIsValid = false
      errors["email"] = t("step1.email_validation")
    }

    if((data.password === "" || data.password.length < 8)){
      formsIsValid = false
      errors["password"] = t("step1.password_validation")
    }

    if(validatePass !== true){
      formsIsValid = false
      errors["password"] = t("step1.password_check")
    }

    if(data.phone_number === "" || data.phone_number.length < 10){
      formsIsValid = false
      errors["phone"] = t("step1.phone_validation")
    }

    setStep1((state) => ({
      ...state,
      errors: errors
    }))
    return formsIsValid
  }

  const onSubmit = (e) => {
    e.preventDefault();
    var phoneNumber = step1.area_phone_number + step1.phone_number;
    var allowAgeRestriction = false;
    var name = step1.name.split(" ");
    var firstName = name[0];
    var lastName = name[1] !== undefined ? name[1] : "";
    var userName = step1.email.split("@")
    var dataDate =
      step1.tgl_lahir.tahun +
      "-" +
      step1.tgl_lahir.bulan +
      "-" +
      step1.tgl_lahir.tgl;
    var age = calculateAge(new Date(dataDate));

    if (age < 18) {
      allowAgeRestriction = true;
    } else {
      allowAgeRestriction = false;
    }

    setStep1((state) => ({
      ...state,
      allowAgeRestriction: allowAgeRestriction,
      first_name: firstName,
      last_name: lastName,
      real_phone_number: phoneNumber,
      age: dataDate,
      username: userName[0]
    }));
    if(handleValidation(step1)){
      setPage(2)
    }
  };

  const validateEmail = (mail) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const validatePassword = (pass, confirmPass) => {
    if(pass !== confirmPass) {
      return false
    }else{
      return true
    }
  }

  

  return (
    <div className="md:w-1/5 lg:w-2/5 mx-auto my-10  sm:container w-4/5 ">
      <div className="font-medium text-lg text-center">{t("step1.title")}</div>
      <form onSubmit={onSubmit} className="my-10">
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="nama"
          >
            {t("step1.name")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="nama"
            type="text"
            placeholder={t("step1.name")}
            value={step1.name}
            onChange={(e) => {
              setStep1((state) => ({ ...state, name: e.target.value }));
            }}
          />
          {step1.errors['name'] !== "" && <span className="text-red-500">{step1.errors['name']}</span>}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            {t("step1.email")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder={t("step1.email_address")}
            value={step1.email}
            onChange={(e) => {
              setStep1((state) => ({ ...state, email: e.target.value }));
            }}
          />
          {step1.errors['email'] !== "" && <span className="text-red-500">{step1.errors['email']}</span>}
        </div>
        <div className="mb-4 password">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            {t("step1.password")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type={showPass ? "text" : "password"}
            name="password"
            placeholder="**********"
            value={step1.password}
            onChange={(e) => {
              setStep1((state) => ({ ...state, password: e.target.value }));
            }}
          />
          {showPass ? (
            <EyeOffIcon
              className="showHidePasswordOne"
              onClick={() => setShowPass((state) => !state)}
            />
          ) : (
            <EyeIcon
              className="showHidePasswordOne"
              onClick={() => setShowPass((state) => !state)}
            />
          )}
        </div>
        <div className="mb-4 confirm_password">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="confirmpassword"
          >
            {t("step1.password_confirm")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmpassword"
            type={showCPass ? "text" : "password"}
            name="confirmpassword"
            placeholder="**********"
            value={step1.confirm_password}
            onChange={(e) => {
              setStep1((state) => ({
                ...state,
                confirm_password: e.target.value,
              }));
            }}
          />
          {showCPass ? (
            <EyeOffIcon
              className="showHidePasswordTwo"
              onClick={() => setCShowPass((state) => !state)}
            />
          ) : (
            <EyeIcon
              className="showHidePasswordTwo"
              onClick={() => setCShowPass((state) => !state)}
            />
          )}
        </div>
        {step1.errors['password'] !== "" && (
          <span className="valid_pass text-red-500">
            {step1.errors['password']}
          </span>
        )}
        <div className="my-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="phonenumber"
          >
            {t("step1.phone")}
          </label>
          <div className="flex flex-wrap items-stretch w-full mb-4 relative shadow appearance-none border rounded h-10">
            <div className="flex flex-wrap md:w-full">
              <input
                type="text"
                id="areacode"
                name="areacode"
                readOnly="readonly"
                placeholder="+62"
                className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative w-1/4"
                value={step1.area_phone_number}
                onChange={(e) => {
                  setStep1((state) => ({
                    ...state,
                    area_phone_number: e.target.value,
                  }));
                }}
              />
              <input
                type="number"
                id="phonenumber"
                className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative w-3/4"
                value={step1.phone_number}
                onKeyPress={(e) => {
                  e.target.value = e.target.value.slice(0,12)
                }}
                onChange={(e) => {
                  setStep1((state) => ({
                    ...state,
                    phone_number: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          {step1.errors['phone'] !== "" && <span className="text-red-500">{step1.errors['phone']}</span>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("step1.date_birth")}
          </label>
          <div className="flex flex-wrap">
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <input
                  className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="tanggal"
                  type="number"
                  placeholder={t("step1.date")}
                  value={step1.tgl_lahir.tgl}
                  onChange={(e) => handleValidateUmur(e)}
                  maxLength="2"
                  min="1"
                />
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <select
                    className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="bulan"
                    value={step1.tgl_lahir.bulan}
                    onChange={(e) =>
                      setStep1((state) => ({
                        ...state,
                        tgl_lahir: {
                          ...state.tgl_lahir,
                          bulan: e.target.value,
                        },
                      }))
                    }
                  >
                    {month.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.abbreviation}
                        </option>
                      );
                    })}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <input
                  className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="tahun"
                  type="number"
                  placeholder={t("step1.year")}
                  value={step1.tgl_lahir.tahun}
                  onChange={(e) => {handleValidateTahun(e)}}
                  maxLength="4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("step1.gender")}
          </label>
          <div className="flex flex-wrap">
            <div className="md:w-auto sm:w-1/2 px-3 mb-6 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="laki"
                  type="radio"
                  value="1"
                  checked={step1.gender === "1"}
                  onChange={(e) =>
                    setStep1((state) => ({
                      ...state,
                      gender: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  {t("step1.man")}
                </label>
              </div>
            </div>
            <div className="md:w-auto sm:w-1/2 px-3 mb-6 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="perempuan"
                  type="radio"
                  value="2"
                  checked={step1.gender === "2"}
                  onChange={(e) =>
                    setStep1((state) => ({
                      ...state,
                      gender: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="perempuan"
                >
                  {t("step1.woman")}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("next")}
          </button>
        </div>
      </form>
    </div>
  );
};
