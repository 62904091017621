import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

export const Step2 = ({ setStep2, step2, setPage }) => {
  const { t } = useTranslation();

  const handleValidateFormTwo = (data) => {
    let formsIsValid = true
    let errors = {}
    if(data.domisili === "") {
      formsIsValid = false
      errors["domisili"] = t("step2.domicile_validation")
    }

    setStep2((state) => ({
      ...state,
      errors: errors
    }))
    return formsIsValid
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if(handleValidateFormTwo(step2)) {
      setPage(3);
    }
  };

  return (
    <div className="md:w-2/6 mx-auto my-10 sm:container w-4/5 mx-5 ">
      <form onSubmit={onSubmit} className="my-10">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("step2.profesi")}
          </label>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap w-full">
                <input
                  className="mt-1 mr-2"
                  id="pelajar"
                  type="radio"
                  value="pelajar"
                  checked={step2.profesi === "pelajar"}
                  onChange={(e) =>
                    setStep2((state) => ({
                      ...state,
                      profesi: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  {t("step2.student")}
                </label>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="mahasiswa"
                  type="radio"
                  value="mahasiswa"
                  checked={step2.profesi === "mahasiswa"}
                  onChange={(e) =>
                    setStep2((state) => ({
                      ...state,
                      profesi: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  {t("step2.college")}
                </label>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="pekerja"
                  type="radio"
                  value="pekerja"
                  checked={step2.profesi === "pekerja"}
                  onChange={(e) =>
                    setStep2((state) => ({
                      ...state,
                      profesi: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  {t("step2.worker")}
                </label>
              </div>
              {step2.profesi === "pekerja" && (
                <div className="test-1 w-full">
                  <div className="inputGroup border rounded">
                    <input
                      id="pns"
                      name="radio"
                      type="radio"
                      value="pns"
                      checked={step2.jenis_profesi === "pns"}
                      onChange={(e) =>
                        setStep2((state) => ({
                          ...state,
                          jenis_profesi: e.target.value,
                        }))
                      }
                    />
                    <label htmlFor="pns">
                      {t("step2.government_employees")}
                    </label>
                  </div>
                  <div className="inputGroup border rounded">
                    <input
                      id="swasta"
                      name="radio"
                      type="radio"
                      value="swa"
                      checked={step2.jenis_profesi === "swa"}
                      onChange={(e) =>
                        setStep2((state) => ({
                          ...state,
                          jenis_profesi: e.target.value,
                        }))
                      }
                    />
                    <label htmlFor="swasta">
                      {t("step2.private_employees")}
                    </label>
                  </div>
                  <div className="inputGroup border rounded">
                    <input
                      id="wiraswasta"
                      name="radio"
                      type="radio"
                      value="wiraswasta"
                      checked={step2.jenis_profesi === "wiraswasta"}
                      onChange={(e) =>
                        setStep2((state) => ({
                          ...state,
                          jenis_profesi: e.target.value,
                        }))
                      }
                    />
                    <label htmlFor="wiraswasta">
                      {t("step2.entrepreneur")}
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("step2.status")}
          </label>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap w-full">
                <input
                  className="mt-1 mr-2"
                  id="menikah"
                  type="radio"
                  value="menikah"
                  checked={step2.status === "menikah"}
                  onChange={(e) =>
                    setStep2((state) => ({ ...state, status: e.target.value }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  {t("step2.married")}
                </label>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="belum_menikah"
                  type="radio"
                  value="belum_menikah"
                  checked={step2.status === "belum_menikah"}
                  onChange={(e) =>
                    setStep2((state) => ({ ...state, status: e.target.value }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  {t("step2.single")}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            {t("step2.income")}
          </label>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap w-full">
                <input
                  className="mt-1 mr-2"
                  id="2juta"
                  type="radio"
                  value="2000000"
                  checked={step2.pendapatan === "2000000"}
                  onChange={(e) =>
                    setStep2((state) => ({
                      ...state,
                      pendapatan: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  &#60; 2000000
                </label>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="4juta"
                  type="radio"
                  value="4999000"
                  checked={step2.pendapatan === "4999000"}
                  onChange={(e) =>
                    setStep2((state) => ({
                      ...state,
                      pendapatan: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  2000000 - 5000000
                </label>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full px-3 mb-3 md:mb-0">
              <div className="flex flex-wrap">
                <input
                  className="mt-1 mr-2"
                  id="5juta"
                  type="radio"
                  value="5000000"
                  checked={step2.pendapatan === "5000000"}
                  onChange={(e) =>
                    setStep2((state) => ({
                      ...state,
                      pendapatan: e.target.value,
                    }))
                  }
                />
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="laki"
                >
                  &#62; 5000000
                </label>
              </div>
            </div>
          </div>
          <div className="my-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="nama"
            >
              {t("step2.domicile")}
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="domisili"
              type="text"
              placeholder={t("step2.domicile")}
              value={step2.domisili}
              onChange={(e) =>
                setStep2((state) => ({
                  ...state,
                  domisili: e.target.value,
                }))
              }
            />
            {step2.errors['domisili'] !== "" && <span className="text-red-500">{step2.errors['domisili']}</span>}
          </div>
        </div>
        <div className="mb-4 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("next")}
          </button>
        </div>
      </form>
    </div>
  );
};
